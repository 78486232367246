export const stringsRegExp = /^[A-Za-z\s]+$/;
export const phoneRegExp = /^[6-9][0-9]{9}$/;
export const pinCodeRegExp = /^\d{6}$/;
export const alphaNumericRegExp = /^[a-zA-Z0-9\s\W_]+$/;
export const numberRegExp =  /^[0-9]+(\.[0-9]+)?$/;
//const decimalRegExp = /^[0-9]+(\.[0-9]+)?$/;
export const ipRegExp =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

export const macAddressRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
export const gstRegexp = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d[Z]{1}[A-Z\d]{1}$/;
export const noSplCharRegExp = /^[A-Za-z0-9\s]+$/;
export const panRegExp = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
export const aadhaarRegExp = /^\d{12}$/;
export const baseAmountRegExp = /^\d+$/;


