'use client';
import {
  FormControl,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useField } from 'formik';

const TextfieldWrapper = ({
  name,
  label,
  textLabel,
  otherProps,
  readOnly,
  required,
  InputProps,
  ...props
}) => {
  const [field, meta] = useField(name);
  const theme = useTheme();

  const configTextfield = {
    ...field,
    size: otherProps?.size,
    ...props,
    label: `${label}${otherProps?.required ? '*' : ''}`,
    InputProps: InputProps || {},
    disabled: readOnly,
    fullWidth: true,
    variant: 'outlined',
    placeholder: props?.placeHolder ? props?.placeHolder : textLabel,
    backgroundcolor: '#ffffff',
    required,
  };

  if (meta && meta.touched && meta.error) {
    configTextfield.error = true;
    configTextfield.helperText = meta.error;
  }

  if (label?.toLowerCase()?.replace(/\s/g, '') === 'contactnumber' || label?.toLowerCase()?.replace(/\s/g, '') === 'mobilenumber' || label?.toLowerCase()?.replace(/\s/g, '') === 'phonenumber' ) {
    // If it is, add the start adornment
    configTextfield.InputProps.startAdornment = (
      <Typography
        disabled
        sx={{
          fontWeight: 500,
          paddingRight: 0.2,
          color: configTextfield.disabled
            ? theme.palette.text.disabled
            : '#212121',
        }}
      >
        +91
      </Typography>
    );
  }

  return (
    <FormControl fullWidth error={Boolean(meta.touched && meta.error)}>
      <Stack spacing={1}>
        <>
          <TextField {...configTextfield} />
        </>
      </Stack>
    </FormControl>
  );
};

export default TextfieldWrapper;
