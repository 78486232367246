'use client';
import ShadowButtonSubmit from '@/components/buttonComponents/ShadowButtonSubmit';
import TextfieldWrapper from '@/components/formComponents/Textfield';
import { otherPropsRequired } from '@/components/formComponents/requiredStyleJson/otherProps';
import { LoadingContext } from '@/components/loadingComponents/LoadingContext';
import { SubmitButtonText } from '@/components/textComponents/buttonTexts/SubmitButtonText';
import { setAlertPopup } from '@/store/alertSlice';
import { setLoginDetails } from '@/store/authSlice';
import { callApi, handleApiError } from '@/utils/apirequest';
import { setCookie } from '@/utils/cookies';
import {
  SuperAdminPasswordValidation,
  nameValidation,
  passwordValidation,
  validateContactNumber,
} from '@/utils/validations/CommonValidations';
import IMAGES from '@/utils/validations/ImagePaths';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';
import { Form, Formik } from 'formik';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

const Login = () => {
  const router = useRouter();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { setLoading } = useContext(LoadingContext);
  const [showPassword, setShowPassword] = useState(false);
  const [role, setRole] = useState(0);

  const INITIAL_FORM_STATE = {
    username: '',
    password: '',
    mobileNumber: '',
  };

  const FORM_VALIDATION = Yup.object().shape({
    username: role === 1 ? nameValidation('User Name', true, 1, 100) : null,
    mobileNumber:
      role === 0 ? validateContactNumber('Phone Number', true) : null,
    password:
      role === 0
        ? passwordValidation('Password', true)
        : SuperAdminPasswordValidation('Password', true),
  });

  // Toggle password visibility
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Prevent default event behavior
  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  // Handle login submission
  const handleLogin = async (values, role) => {
    setLoading(true);

  
    const fieldAgentData = {
      username: values?.mobileNumber.trim(),
      password: values?.password.trim(),
    };

    const superadminData = {
      username: values?.username.trim(),
      password: values?.password.trim(),
    };
   

    const data = role === 1 ? superadminData : fieldAgentData;

    try {
      const response = await callApi('adminLogin', data);
      setLoading(false);

      if (response.data.result) {
        setCookie('token', response.data.result.token, 30);
        dispatch(setLoginDetails(response.data.result));
        dispatch(
          setAlertPopup({
            message: response.data.message,
            type: 'success',
            duration: 1000,
          })
        );
        router.push(`/dashboard`);
      } else {
        console.error('Failed to fetch:', response.data.message);
        dispatch(
          setAlertPopup({
            message: response.data.message,
            type: 'error',
            duration: 3000,
          })
        );
      }
    } catch (err) {
      console.error('Error:', err);
      handleApiError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleRoleChange = (event, newValue, resetForm) => {
    setRole(newValue);
    resetForm();
  };
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        border: '2px solid #4caf50',
      }}
    >
      <Formik
        initialValues={INITIAL_FORM_STATE}
        validationSchema={FORM_VALIDATION}
        onSubmit={async (values, { resetForm }) => {
          localStorage.clear();
          await handleLogin(values, role, { resetForm });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          values,
          resetForm,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Card
              sx={{
                maxWidth: 700,
                margin: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box>
                <Image
                  src={IMAGES?.LOGO}
                  alt="logo"
                  width={70}
                  height={70}
                  priority
                  style={{ borderRadius: '50%' }}
                />
              </Box>
              <CardContent>
                <Typography
                  variant="h3"
                  textAlign="center"
                  paddingBottom="10px"
                >
                  LNK Finance
                </Typography>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingBottom: '15px',
                  }}
                >
                  <Tabs
                    value={role}
                    onChange={(event, newValue) =>
                      handleRoleChange(event, newValue, resetForm)
                    }
                  >
                    <Tab label="Field Agent" value={0} />
                    <Tab label="Super Admin" value={1} />
                  </Tabs>
                </Box>

                <Card
                  sx={{
                    margin: 'auto',
                    border: '1px solid #DDDDDD',
                    borderRadius: '13px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    px: 10,
                    py: 4,
                  }}
                >
                  <Box sx={{ width: '100%', mt: 2 }}>
                    {role === 0 && (
                      <Grid
                        container
                        spacing={2}
                        alignItems={'center'}
                        justifyContent={'center'}
                      >
                        <Grid item xs={12}>
                          <TextfieldWrapper
                            // autoFocus
                            // autoComplete="off"
                            name="mobileNumber"
                            label="Phone Number"
                            otherProps={otherPropsRequired}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextfieldWrapper
                            // autoComplete="off"
                            name="password"
                            label="Password"
                            type={showPassword ? 'text' : 'password'}
                            otherProps={otherPropsRequired}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    size="large"
                                  >
                                    {showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          md={6}
                          container
                          justifyContent="center"
                        >
                          <ShadowButtonSubmit
                            height="50px"
                            width="100%"
                            minwidth="250px"
                            maxwidth="275px"
                            backgroundcolor={theme.palette.primary.main}
                            type="submit"
                            onClick={handleSubmit}
                          >
                            <SubmitButtonText color="#fff">
                              Login
                            </SubmitButtonText>
                          </ShadowButtonSubmit>
                        </Grid>
                      </Grid>
                    )}
                    {role === 1 && (
                      <Grid
                        container
                        spacing={2}
                        alignItems={'center'}
                        justifyContent={'center'}
                      >
                        <Grid item xs={12}>
                          <TextfieldWrapper
                            // autoFocus
                            // autoComplete="off"
                            name="username"
                            label="User Name"
                            otherProps={otherPropsRequired}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextfieldWrapper
                            // autoComplete="off"
                            name="password"
                            label="Password"
                            type={showPassword ? 'text' : 'password'}
                            otherProps={otherPropsRequired}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    size="large"
                                  >
                                    {showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          md={6}
                          container
                          justifyContent="center"
                        >
                          <ShadowButtonSubmit
                            height="50px"
                            width="100%"
                            minwidth="250px"
                            maxwidth="275px"
                            backgroundcolor={theme.palette.primary.main}
                            type="submit"
                            onClick={handleSubmit}
                          >
                            <SubmitButtonText color="#fff">
                              Login
                            </SubmitButtonText>
                          </ShadowButtonSubmit>
                        </Grid>
                      </Grid>
                    )}
                  </Box>
                </Card>
              </CardContent>
            </Card>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default Login;
