import {
  alphaNumericRegExp,
  noSplCharRegExp,
  numberRegExp,
  phoneRegExp,
  stringsRegExp,
} from '@/utils/validations/regex';
import * as Yup from 'yup';

export const emailValidation = (fieldName, required) => {
  let validation = Yup.string()
    .trim()
    .email(`Please enter a valid ${fieldName}`)
    .min(1, `${fieldName} must be at least 1 character`)
    .max(50, `${fieldName} cannot be more than 50 characters`);

  if (required) {
    validation = validation.required(`${fieldName} is required`);
  }

  return validation;
};
export const staticDropDownValidation = (
  fieldName,
  required,
  dropDownValues,
  isorare = 'is'
) => {
  let schema = Yup.string().nullable();

  if (required) {
    schema = schema.required(`${fieldName} ${isorare} required`);
  }

  return schema.oneOf(
    dropDownValues.map(item => String(item.value)),
    `Please select a ${fieldName} from the drop-down`
  );
};

export const passwordValidation = (fieldName, required) => {
  let validation = Yup.string()
    .trim()
    .min(8, `${fieldName} must be at least 8 characters`)
    .max(50, `${fieldName} cannot be more than 50 characters`)
    .matches(/[A-Z]/, `${fieldName} must contain at least one uppercase letter`)
    .matches(/[a-z]/, `${fieldName} must contain at least one lowercase letter`)
    .matches(/[0-9]/, `${fieldName} must contain at least one number`)
    .matches(
      /[\W_]/,
      `${fieldName} must contain at least one special character`
    );

  if (required) {
    validation = validation.required(`${fieldName} is required`);
  }

  return validation;
};

export const SuperAdminPasswordValidation = (fieldName, required) => {
  let validation = Yup.string()
    .trim()
    .min(8, `${fieldName} must be at least 8 characters`)
    .max(50, `${fieldName} cannot be more than 50 characters`);

  if (required) {
    validation = validation.required(`${fieldName} is required`);
  }

  return validation;
};

export const validateContactNumber = (fieldName, required) => {
  let validation = Yup.string()
    .trim()
    .matches(phoneRegExp, `Enter a valid ${fieldName}`)
    .min(10, `${fieldName} must be 10 digit number`)
    .max(10, `${fieldName} must be 10 digit number`);

  if (required) {
    validation = validation.required(`${fieldName} is required`);
  }

  return validation;
};

export const nameValidation = (
  fieldName,
  required,
  minCharacters,
  maxCharacters
) => {
  let validation = Yup.string()
    .trim()
    .min(
      minCharacters || 1,
      `${fieldName} must be at least ${minCharacters || 1} character`
    )
    .max(
      maxCharacters || 100,
      `${fieldName} cannot be more than ${maxCharacters || 100} characters`
    )
    .matches(
      stringsRegExp,
      `${fieldName} cannot contain numbers or special characters`
    )
    .nullable();
  if (required) {
    validation = validation.required(`${fieldName} is required`);
  }

  return validation;
};

export const nameValidationAlphanumeric = (
  fieldName,
  required,
  minCharacters,
  maxCharacters
) => {
  let validation = Yup.string()
    .trim()
    .min(
      minCharacters || 1,
      `${fieldName} must be at least ${minCharacters || 1} character${minCharacters > 1 ? 's' : ''}`
    )
    .max(
      maxCharacters || 100,
      `${fieldName} cannot be more than ${maxCharacters || 100} characters`
    )
    .matches(
      alphaNumericRegExp,
      `${fieldName} must contain only alphanumeric characters`
    )
    .nullable();

  if (required) {
    validation = validation.required(`${fieldName} is required`);
  }

  return validation;
};

export const nameValidationWithoutSplChar = (
  fieldName,
  required,
  minCharacters,
  maxCharacters
) => {
  let validation = Yup.string()
    .trim()
    .min(
      minCharacters || 1,
      `${fieldName} must be at least ${minCharacters || 1} character${minCharacters > 1 ? 's' : ''}`
    )
    .max(
      maxCharacters || 100,
      `${fieldName} cannot be more than ${maxCharacters || 100} characters`
    )
    .matches(
      noSplCharRegExp,
      `${fieldName} must contain only letters & numbers`
    )
    .nullable();

  if (required) {
    validation = validation.required(`${fieldName} is required`);
  }

  return validation;
};

export const numberValidation = (fieldName, required, minNumber, maxNumber) => {
  let validation = Yup.string()
    .matches(
      numberRegExp,
      `${fieldName} must be a number and may contain a decimal point`
    )
    .min(
      minNumber || 0,
      `${fieldName} must be greater than or equal to ${minNumber || 1} digits`
    )
    .max(
      maxNumber || 100,
      `${fieldName} must be less than or equal to ${maxNumber || 100} digits`
    )
    .nullable();

  if (required) {
    validation = validation.required(`${fieldName} is required`);
  }
  validation = validation.test(
    'not-starts-with-zero',
    `${fieldName} cannot start with zero`,
    value => {
      if (!value) return true; // If value is empty or null, skip validation
      const firstChar = value.charAt(0);
      return firstChar !== '0';
    }
  );
  return validation;
};

export const adhaarNumberValidation = (
  fieldName,
  required,
  length,
  min,
  max
) => {
  let validation = Yup.string()
    .trim()
    .matches(/^[0-9]+$/, `${fieldName} must contain only numbers`)
    .nullable();
  if (length !== undefined) {
    validation = validation.matches(
      new RegExp(`^\\d{${length}}$`),
      `${fieldName} must be ${length} digits`
    );
  }
  if (min !== undefined && max !== undefined) {
    validation = validation.matches(
      new RegExp(`^\\d{${min},${max}}$`),
      `${fieldName} must be between ${min} and ${max} digits`
    );
  } else if (min !== undefined) {
    validation = validation.matches(
      new RegExp(`^\\d{${min},}$`),
      `${fieldName} must be at least ${min} digits`
    );
  } else if (max !== undefined) {
    validation = validation.matches(
      new RegExp(`^\\d{0,${max}}$`),
      `${fieldName} must not exceed ${max} digits`
    );
  }

  if (required) {
    validation = validation.required(`${fieldName} is required`);
  }

  return validation;
};
export const interestValidation = () => {
  return Yup.number()
    .typeError('Interest must be a number')
    .min(0, 'Interest cannot be negative')
    .max(100, 'Interest cannot be greater than 100')
    .test('max-decimal', 'Interest cannot be greater than 100', value => {
      return value <= 100;
    })
    .test(
      'decimal-places',
      'Interest can have up to two decimal places',
      value => {
        if (value === undefined || value === null || value === '') return true;
        // Regular expression to allow up to two decimal places
        return /^\d+(\.\d{1,2})?$/.test(value.toString());
      }
    );
};
// export const ValidateNumber = (fieldName, required, minNumber, maxNumber) => {
//   let validation = Yup.number()
//     .min(minNumber || 1, `${fieldName} must be greater than ${minNumber || 0}`)
//     .max(
//       maxNumber || Infinity,
//       `${fieldName} cannot be greater than ${maxNumber || Infinity}`
//     )
//     .nullable();

//   if (required) {
//     validation = validation.required(`${fieldName} is required`);
//   }

//   return validation;
// };
export const ValidateNumber = (
  fieldName,
  required,
  minNumber,
  maxNumber,
  allowZero
) => {
  let validation = Yup.number()
    .typeError(`${fieldName} must be a number`)

    .min(
      minNumber || (allowZero ? 0 : 1),
      `${fieldName} must be greater than ${minNumber || (allowZero ? -1 : 0)}`
    )
    .max(
      maxNumber || Infinity,
      `${fieldName} cannot be greater than ${maxNumber || Infinity}`
    )

    .nullable();

  if (required) {
    validation = validation.required(`${fieldName} is required`);
  }

  return validation;
};

export const dropdownValidationSchema = (fieldName, min, max) =>
  Yup.mixed()
    .nullable()
    .test(
      'input-value-length',
      toSentenceCase(
        `${fieldName} should be between ${min} to ${max} characters`
      ),
      value => {
        if (
          typeof value === 'object' &&
          value &&
          Object.prototype.hasOwnProperty.call(value, 'inputValue')
        ) {
          const inputValue = value.inputValue.trim();

          return inputValue.length >= min && inputValue.length <= max;
        }
        return true;
      }
    )
    .required(`Please select from ${fieldName} dropdown`);

function toSentenceCase(str) {
  if (!str) return '';
  const firstChar = str.charAt(0);
  return firstChar.toUpperCase() + str.slice(1).toLowerCase();
}

// export const validatePassword = (password) => {
//   // Regular expression to match password criteria
//   const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;

//   // Test the password against the regular expression
//   return regex.test(password);
// };

export const MAX_FILES = 5;

export const MAX_FILE_SIZE = 2097152;
