import { setAlertPopup } from '@/store/alertSlice';
import { store } from '@/store/store';
import axios from 'axios';
import config from 'config.js';

const baseUrl = config.apiUrl;

function callApi(path, cData, cToken, queryParams = {}) {
  const apiPaths = {
    //login
    adminLogin: `${baseUrl}/api/superadmin/login`,
    adminDashboard: `${baseUrl}/api/superadmin/adminDashboard`,
    //pigmy
    pigmyCollectionList: `${baseUrl}/api/account/pigmyCollectionList`,
    collectPigmy: `${baseUrl}/api/account/collectPigmy`,
    //loan
    collectLoan: `${baseUrl}/api/account/collectLoan`,
    loanCollectionList: `${baseUrl}/api/account/loanCollectionList`,

    //customer
    getListOfCustomerPagination: ` ${baseUrl}/api/customer/getListOfCustomerPagination`,
    getListOfCustomer: `${baseUrl}/api/customer/getListOfCustomer`,
    createCustomer: ` ${baseUrl}/api/customer/createcustomer`,
    updateCustomer: ` ${baseUrl}/api/customer/updateCustomer`,
    deleteCustomer: ` ${baseUrl}/api/customer/deleteCustomer`,
    getCustomer: `${baseUrl}/api/customer/getCustomer`,
    updateCustomerDocuments: ` ${baseUrl}/api/customer/updateCustomerDocuments`,
    updateCustomerStatus: `${baseUrl}/api/customer/updateCustomerStatus`,
    deleteCustomerDocument: `${baseUrl}/api/customer/deleteCustomerDocuments`,
    //Account
    getAccountById: `${baseUrl}/api/account/getAccountById`,
    getListOfAccount: `${baseUrl}/api/account/getListOfAccount`,
    searchCustomerAccount: `${baseUrl}/api/account/searchCustomerAccount`,
    createAccount: `${baseUrl}/api/account/createAccount`,
    withdrawDetailsOnId: ` ${baseUrl}/api/account/withdrawDetailsOnId`,
    withdrawPigmyAmount: ` ${baseUrl}/api/account/withdrawPigmyAmount`,

    // Close Loan Account
    closeLoanDetailsOnId: `${baseUrl}/api/account/closeLoanDetailsOnId`,
    closeLoanAccount: ` ${baseUrl}/api/account/closeLoanAccount`,
    //reports
    pendingReports: `${baseUrl}/api/account/pendingReports`,

    //feild agent
    createFieldAgent: `${baseUrl}/api/fieldAgent/createFieldAgent`,
    updateFieldAgent: `${baseUrl}/api/fieldAgent/updateFieldAgent`,
    getListOfFieldAgent: `${baseUrl}/api/fieldAgent/getListOfFieldAgent`,
    getFieldAgent: `${baseUrl}/api/fieldAgent/getFieldAgent`,
    updateFieldAgentStatus: `${baseUrl}/api/fieldAgent/updateFieldAgentStatus`,
    updateDocuments: `${baseUrl}/api/fieldAgent/updateDocuments`,
    deleteFieldAgentDocument: `${baseUrl}/api/fieldAgent/deleteFieldAgentDocuments`,


    //area
    ListAreaAndAgent: `${baseUrl}/api/fieldAgent/ListAreaAndAgent`,
  };

  let apiMethod = null;
  let apiUrl = null;
  let apiData = null;
  let apiHeaders = null;

  //     const authtoken = cToken.data.access_token;
  apiHeaders = {
    // 'Content-Type': 'application/json',
    enctype: 'multipart/form-data',

    // data: formData,
    processData: false,
    contentType: false,
  };
  if (cToken) {
    apiHeaders['x-access-token'] = cToken;
  }

  switch (path) {
    case 'adminLogin':
    case 'adminDashboard':
    case 'pigmyCollectionList':
    case 'loanCollectionList':
    case 'getListOfCustomerPagination':
    case 'getAccountById':
    case 'searchCustomerAccount':
    case 'getListOfAccount':
    case 'collectLoan':
    case 'collectPigmy':
    case 'updateCustomer':
    case 'createCustomer':
    case 'deleteCustomer':
    case 'getCustomer':
    case 'createAccount':
    case 'updateCustomerDocuments':
    case 'withdrawPigmyAmount':
    case 'withdrawDetailsOnId':
    case 'closeLoanDetailsOnId':
    case 'closeLoanAccount':
    case 'updateCustomerStatus':
    case 'pendingReports':
    case 'createFieldAgent':
    case 'updateFieldAgent':
    case 'getFieldAgent':
    case 'updateFieldAgentStatus':
    case 'updateDocuments':
      case 'deleteFieldAgentDocument':
      case 'deleteCustomerDocument':
      apiMethod = 'POST';
      apiUrl = apiPaths[path];
      apiData = cData;
      break;

    case 'ListAreaAndAgent':
    case 'getListOfFieldAgent':
      apiMethod = 'GET';
      apiUrl = apiPaths[path];
      apiData = cData;
      break;

    default:
      break;
  }

  return axios({
    url: apiUrl,
    method: apiMethod,
    data: apiData,
    params: queryParams,
    headers: apiHeaders,
  });
}

// const handleApiError = error => {
//   if (!error.response) {
//     // Network error
//     store.dispatch(
//       setAlertPopup({
//         message: 'No internet connection.',
//         type: 'error',
//         duration: 3000,
//       })
//     );
//   } else {
//     // Server error
//     store.dispatch(
//       setAlertPopup({
//         message: 'Something went wrong. Please try again!',
//         type: 'error',
//         duration: 3000,
//       })
//     );
//   }
// };

const handleApiError = error => {
  if (!error.response) {
    // Network error or no response received
    if (typeof navigator !== 'undefined' && !navigator.onLine) {
      // Offline error
      store.dispatch(
        setAlertPopup({
          message: 'No internet connection.',
          type: 'error',
          duration: 3000,
        })
      );
    } else {
      // General network error
      store.dispatch(
        setAlertPopup({
          message: 'Network error. Please check connection.',
          type: 'error',
          duration: 3000,
        })
      );
    }
  } else {
    // Server response received but it indicates an error
    switch (error.response.status) {
      case 404:
        store.dispatch(
          setAlertPopup({
            message: 'Resource not found (404).',
            type: 'error',
            duration: 3000,
          })
        );
        break;
      case 403:
        store.dispatch(
          setAlertPopup({
            message:
              'Forbidden (403). You do not have permission to access this resource.',
            type: 'error',
            duration: 3000,
          })
        );
        break;
      default:
        store.dispatch(
          setAlertPopup({
            message: 'Something went wrong. Please try again!',
            type: 'error',
            duration: 3000,
          })
        );
        break;
    }
  }
};

export { baseUrl, callApi, handleApiError };
