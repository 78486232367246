const config = {
  // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
  // like '/berry-material-react/react/default'
  basename: '',
  defaultPath: '/',
  fontFamily: `'Poppins', sans-serif`,
  borderRadius: 10,
  appName: 'LNK Finance',
  appVersion: '1.0.9',
  appVersionProd: '0.0.0',
  apiUrl: 'https://lnkapp.nellabs.in',

  isProd: false,

  superAdmin: {
    login: '/login',
    dashboard: '/dashboard',
    customer: '/customer',
    fieldAgent: '/field-agent',
    pigmyReports: '/reports/pigmy-collection',
    loanReports: '/reports/loan-collection',
    loanAccount: '/loan-account',
    pigmyAccount: '/pigmy-account',
    pendingReports: '/active-accounts',
  },
};

export default config;
